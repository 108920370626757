import Helper from '../helpers/helper'
import ContractStatus from './contract_status'
import LogChange from './log_change'
import Request from './request'
import Loading from './loading'
import Modal from './modal'
import CustomerModalBody from './customer_modal_body'
import DocumentList from './document_list'
import Uploader from './uploader'
import ContractList from './contract_list'
import Comments from './comments'
import Device from './device'
import QRPayment from './qr_payment'
import DeviceImages from './device_images'
import DeviceJobDetail from './device_job_detail'
import ConsultationJobsDetail from './consultation_jobs_detail'

class ContractModalBody extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tab: 'basic',
      contracts: [],
      related_devices: true,
      operator_upload_files: [],
      technician_upload_files: [],
      devices: (props.contract && props.contract.place ? props.contract.place.devices : [])
    };
  }

  componentDidMount() {
    this.fetchContracts()
    this.fetchManufacturers()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.contract.id != this.props.contract.id) {
      this.setState({
        devices: (this.props.contract && this.props.contract.place ? this.props.contract.place.devices : [])
      })

      this.fetchContracts()
      this.fetchManufacturers()
    }
  }

  fetchManufacturers() {
    if (!['warehouseman','superadmin','accountant'].includes(document.body.dataset.currentUserRights)) return true

    const currentManufacturersRequest = jQuery.ajax({
      method: 'GET',
      url: '/api/v1/manufacturers',
      data: {
        ids: this.state.devices.map(i => i.manufacturer_id).join(',')
      },
      beforeSend: (xhr) => {
        if (this.props.accessToken) {
          xhr.setRequestHeader ("Authorization", "Bearer " + this.props.accessToken.access_token)
        } else {
          xhr.setRequestHeader ("Authorization", "Bearer " + document.body.dataset.jwt)
        }

        if (this.currentManufacturersRequest) {
          this.currentManufacturersRequest.abort()
        }
        // this.setState({loading: true})
        this.currentRequest = null
      },
      success: (data) => {
        this.setState((state, props) => {
          let devices = state.devices.map(device => {
            let manufacturer = data.results.find(ii => ii.id == device.manufacturer_id)
            if (device.manufacturer_id && manufacturer) {
              return Object.assign(device, {manufacturer_link: manufacturer.link})
            } else {
              return device
            }
          })
        })
      },
      complete: () => {
        // this.setState({loading: false})
        this.currentRequest = null
      }
    })
    this.currentManufacturersRequest = currentManufacturersRequest
  }

  fetchContracts() {
    const currentRequest = jQuery.ajax({
      method: 'GET',
      url: '/api/v1/contracts',
      data: {
        customer_id: this.props.contract.customer_id,
        parent_id: this.props.contract.id,
        device_uuids: this.props.contract.device_jobs.filter(i => i.checked).map(i => i.device_uuid)
      },
      beforeSend: (xhr) => {
        if (this.props.accessToken) {
          xhr.setRequestHeader ("Authorization", "Bearer " + this.props.accessToken.access_token)
        } else {
          xhr.setRequestHeader ("Authorization", "Bearer " + document.body.dataset.jwt)
        }

        if (this.currentRequest) {
          this.currentRequest.abort()
        }
        this.setState({loading: true})
        this.currentRequest = null
      },
      success: (data) => {
        this.setState({contracts: data.results})
      },
      complete: () => {
        this.setState({loading: false})
        this.currentRequest = null
      }
    })
    this.currentRequest = currentRequest
  }

  handleChangeTab(tab, e) {
    e.preventDefault()
    this.setState({tab: tab})
  }

  showCustomer(customer, tab='basic', e) {
    e.preventDefault()
    e.stopPropagation()

    let parentCustomer = this.props.parentCustomer || this.props.customer

    let modalBody = (
      <CustomerModalBody
        customer={customer}
        master={this.props.master}
        parentCustomer={parentCustomer}
        accessToken={this.props.accessToken} />
    )

    this.setState({tab: tab})

    ReactDOM.render(
      React.createElement(Modal, {
        title: parentCustomer && parentCustomer.id != customer.id ? (
          <span>
            <a
              href="#"
              className="btn btn-outline-primary"
              style={{marginRight: '1rem'}}
              onClick={this.showCustomer.bind(this, parentCustomer, 'related_contracts')}>Zpět na {parentCustomer.id}</a>
            {`Detail zákazníka ${customer.id}`}
          </span>
        ) : (
          `Detail zákazníka ${customer.id}`
        ),
        body: modalBody,
        linkTitle: 'Upravit',
        link: `/is/customers/${customer.id}/edit`
      }),
      document.querySelector('#modal-container')
    )
  }

  showContract(contract, tab='basic', e) {
    e.preventDefault()
    e.stopPropagation()

    let parentContract = this.props.parentContract || this.props.contract

    let modalBody = (
      <ContractModalBody
        contract={contract}
        master={this.props.master}
        parentContract={parentContract}
        parentCustomer={this.props.parentCustomer}
        accessToken={this.props.accessToken} />
    )

    this.setState({tab: tab})

    // LinkEvent
    let linkEvent
    if (this.props.master) {
      if (
        this.props.master.state.user.id == contract.user_id &&
        ((moment(contract.finished_at) >= moment().add(-30, 'minutes') && contract.status == 'finished') ||
        contract.status != 'finished')
      ) {
        linkEvent = (e) => {
          e.preventDefault()
          jQuery('#modal').modal('hide')
          this.props.master.setState({page: 'contract-form', editContract: contract})
          history.pushState({page: 'contract-form'}, null, 'contract-form')
        }
      } else {
        linkEvent = null
      }
    } else if (!contract.locked) {
      linkEvent = (e) => {
        e.preventDefault()
        Turbolinks.visit(`/is/contracts/${contract.id}/edit`)
      }
    }

    let title
    let titleText = `Detail ${contract.kind == 'reservation' ? 'rezervace' : 'zakázky'} ${contract.id}`

    if (contract.action_type && contract.kind != 'reservation') {
      titleText += ` - ${I18n.t(`action-types.${contract.action_type}`)}`
    }

    if (parentContract && parentContract.id != contract.id) {
      title = (
        <span>
          <a
            href="#"
            className="btn btn-outline-primary"
            style={{marginRight: '1rem'}}
            onClick={this.showContract.bind(this, parentContract, 'related_contracts')}>Zpět na {parentContract.id}</a>
          {titleText}
        </span>
      )
    } else if (this.props.parentCustomer) {
      title = (
        <span>
          <a
            href="#"
            className="btn btn-outline-primary"
            style={{marginRight: '1rem'}}
            onClick={this.showCustomer.bind(this, this.props.parentCustomer, 'related_contracts')}>Zpět na zákazníka {this.props.parentCustomer.id}</a>
          {titleText}
        </span>
      )
    } else {
      title = titleText
    }

    ReactDOM.render(
      React.createElement(Modal, {
        title: title,
        body: modalBody,
        linkTitle: 'Upravit',
        linkEvent: linkEvent,
        contract: contract,
        master: this.props.master
        // link: `/is/contracts/${contract.id}/edit`
      }),
      document.querySelector('#modal-container')
    )
  }

  handleUploads(data, kind) {
    this.props.contract[`${kind}_upload_files`].push(data)
    this.forceUpdate()
  }

  handleRelatedDevicesChange(e) {
    this.setState({ related_devices: e.target.checked })
  }

  render() {
    let users = (this.props.master ? this.props.master.state.users : window.users)
    let user = users.filter(i => i.id == this.props.contract.user_id)[0]

    let contracts = this.state.contracts
    let device_uuids = []
    if (this.props.contract.device_jobs && this.props.contract.device_jobs.length) {
      device_uuids = this.props.contract.device_jobs.filter(i => i.checked).map(i => i.device_uuid)
    }
    if (this.state.related_devices) {
      contracts = contracts.filter(i => {
        if (
          _.intersection(
            i.device_jobs.filter(i => i.checked).map(ii => ii.device_uuid), device_uuids
          ).length
        ) {
          return true
        } else {
          return false
        }
      })
    }

    // Devices
    let devices = []
    if (this.props.contract.place && this.props.contract.place.devices) {
      devices = this.props.contract.place.devices
    }

    // Emails & Phones
    let emails = (
      (
        this.props.contract.emails ? this.props.contract.emails.split(',').map(i => {
          return {
            value: i,
            note: this.props.contract.customer?.emails_data.find(ii => ii.value == i)?.note
          }
        }) : null
      ) || (
        this.props.contract.customer?.emails_data.length > 1 ? this.props.contract.customer?.emails_data.filter(i => i.default).map(i => {return {value: i.value, note: i.note}}) : this.props.contract.customer?.emails_data.map(i => {return {value: i.value, note: i.note}})
      ) ||
      []
    )

    let phones = (
      (
        this.props.contract.phones ? this.props.contract.phones.split(',').map(i => {
          return {
            value: i,
            note: this.props.contract.customer?.phones_data.find(ii => ii.value == i)?.note
          }
        }) : null
      ) || (
        this.props.contract.customer?.phones_data.length > 1 ? this.props.contract.customer?.phones_data.filter(i => i.default).map(i => {return {value: i.value, note: i.note}}) : this.props.contract.customer?.phones_data.map(i => {return {value: i.value, note: i.note}})
      ) ||
      []
    )

    return (
      <div className="contract-modal-body">
        {/*
          <div className="tabs-nav">
            <a href="#" onClick={this.handleChangeTab.bind(this, 'basic')} className={classNames({'active': this.state.tab == 'basic'})}>Základní</a>
            <a href="#" onClick={this.handleChangeTab.bind(this, 'info')} className={classNames({'active': this.state.tab == 'info'})}>Rozpis</a>
            <a href="#" onClick={this.handleChangeTab.bind(this, 'photo')} className={classNames({'active': this.state.tab == 'photo'})}>Fotografie</a>
            <a href="#" onClick={this.handleChangeTab.bind(this, 'log')} className={classNames({'active': this.state.tab == 'log'})}>Log</a>
          </div>
        */}

        <ul className="nav nav-pills mb-4">
          <li className="nav-item">
            <a href="#" onClick={this.handleChangeTab.bind(this, 'basic')} className={classNames('nav-link', {'active': this.state.tab == 'basic'})}>
              Základní
            </a>
          </li>
          <li className={classNames('nav-item', {'d-none': this.props.contract.kind == 'reservation' || this.props.contract.action_type == 'help'})}>
            <a href="#" onClick={this.handleChangeTab.bind(this, 'info')} className={classNames('nav-link', {'active': this.state.tab == 'info'})}>
              Rozpis
            </a>
          </li>
          <li className="nav-item">
            <a href="#" onClick={this.handleChangeTab.bind(this, 'photo')} className={classNames('nav-link', {'active': this.state.tab == 'photo'})}>
              Soubory
            </a>
          </li>
          <li className="nav-item">
            <a href="#" onClick={this.handleChangeTab.bind(this, 'documents')} className={classNames('nav-link', {'active': this.state.tab == 'documents'})}>
              Doklady
            </a>
          </li>
          <li className={classNames('nav-item', {'d-none': this.props.contract.kind == 'reservation'})}>
            <a href="#" onClick={this.handleChangeTab.bind(this, 'requests')} className={classNames('nav-link', {'active': this.state.tab == 'requests'})}>
              Požadavky
            </a>
          </li>
          <li className="nav-item">
            <a href="#" onClick={this.handleChangeTab.bind(this, 'related_contracts')} className={classNames('nav-link', {'active': this.state.tab == 'related_contracts'})}>
              Související zakázky
            </a>
          </li>
          <li className="nav-item">
            <a href="#" onClick={this.handleChangeTab.bind(this, 'comments')} className={classNames('nav-link', {'active': this.state.tab == 'comments'})}>
              Komentáře
            </a>
          </li>
          <li className="nav-item">
            <a href="#" onClick={this.handleChangeTab.bind(this, 'log')} className={classNames('nav-link', {'active': this.state.tab == 'log'})}>
              Log
            </a>
          </li>
        </ul>


        <div className={classNames({'d-none': this.state.tab != 'basic'})}>
          <div className="row mb-3">
            <div className="col">
              <strong>Datum</strong>
              <br/>
              {moment(this.props.contract.start_time).format('DD.MM.YYYY dddd')}
            </div>
            <div className="col">
              <strong>Čas</strong>
              <br/>
              {moment(this.props.contract.start_time).format('HH:mm')} - {moment(this.props.contract.end_time).format('HH:mm')}
            </div>
            <div className={classNames('col', {'hidden': this.props.contract.status == 'finished'})}>
              <strong>Čas skutečný</strong>
              <br/>
              {this.props.contract.started_at ? moment(this.props.contract.started_at).format('HH:mm') : '__:__'} - {this.props.contract.finished_at ? moment(this.props.contract.finished_at).format('HH:mm') : '__:__'}
            </div>
          </div>
          <p>

          </p>
          <div className={classNames('row mb-3', {'d-none': this.props.contract.kind == 'reservation'})}>
            <div className="col">
              <strong>Stav</strong>
              <br/>
              <div style={{fontSize: '1.2rem'}}>
                <ContractStatus status={this.props.contract.status} message={this.props.contract.message_to_operator} />
              </div>
            </div>

            <div className="col">
              <strong>Výjezd</strong>
              <br/>
              {this.props.contract.express ? 'Ano' : 'Ne'}
            </div>

            <div className="col">
              <strong>Nevyřízené požadavky</strong>
              <br/>
              <span className={classNames('badge', {'badge-danger': this.props.contract.requests.filter(i => i.status == 'opened').length, 'badge-secondary': !this.props.contract.requests.filter(i => i.status == 'opened').length})}>
                {this.props.contract.requests.filter(i => i.status == 'opened').length}
              </span>
            </div>
          </div>

          <p className={classNames('p-note', {'d-none': !this.props.contract.note})}>
            <strong>Interní poznámka</strong>
            <br/>
            {this.props.contract.note}
          </p>
          {this.props.contract.kind == 'standard' ? (
            <React.Fragment>
              <div className="row mb-3">
                <div className="col">
                  <strong>Zákazník</strong>
                  <span className="badge badge-secondary" style={{marginLeft: '4px'}}>{this.props.contract.customer.id}</span>
                  {this.props.contract.customer.has_id_no && (
                    <>
                      <span className="badge badge-info" style={{marginLeft: '4px'}}>IČ</span>
                      {this.props.contract.customer.vat_payer ? (
                        <span className="badge badge-info" style={{marginLeft: '4px'}}>Plátce DPH</span>
                      ) : (
                        <span className="badge badge-secondary" style={{marginLeft: '4px'}}>Neplátce DPH</span>
                      )}
                    </>
                  )}
                  <br/>
                  {this.props.contract.customer.name}
                </div>
                <div className={classNames('col', {
                    'd-none': this.props.master && this.props.master.state.user && !this.props.master.state.user.can_see_phones
                  })}>
                  <strong>Telefon</strong>
                  <br/>
                  {phones.filter(i => i).map(phone =>
                    <div key={phone.value.replace(/\s/g,"")}>
                      <a href={`tel:${phone.value.replace(/\s/g,"")}`}>{Helper.formatPhone(phone.value.replace(/\s/g,""))}</a> {phone.note}
                    </div>
                  )}
                </div>
                <div className="col">
                  <strong>E-mail</strong>
                  <br/>
                  {this.props.contract.customer.email_status == 'has_not' ? (
                    'Nemá email'
                  ) : null}
                  {this.props.contract.customer.email_status == 'missing' ? (
                    'Doplnit'
                  ) : null}
                  {this.props.contract.customer.email_status == 'has' ? (
                    emails.filter(i => i.value).map(email =>
                      <div key={email.value.replace(/\s/g,"")}>
                        <a href={`mailto:${email.value}`}>{email.value}</a> {email.note}
                      </div>
                    )
                  ) : null}
                </div>
              </div>
              <div className="row mb-3">
                <div className={classNames('col', {'d-none': !this.props.contract.customer.person})}>
                  <strong>{I18n.t('activerecord.attributes.customer.person')}</strong>
                  <br/>
                  {this.props.contract.customer.person}
                </div>
                <div className="col">
                  <strong>Adresa</strong>
                  <br/>
                  {this.props.contract.place ? (
                    [
                      this.props.contract.place.street,
                      this.props.contract.place.city,
                      this.props.contract.place.zip
                    ].filter(i => i).join(', ')
                  ) : null}
                </div>
                <div className="col">
                  <strong>Umístění</strong>
                  <br/>
                  {this.props.contract.place.location}
                </div>
                <div className="col">
                  {this.props.contract.customer.has_billing_address ? (
                    <React.Fragment>
                      <strong>Fakturační adresa zákazníka</strong>
                      <br/>
                      {[
                        this.props.contract.customer.billing_name,
                        this.props.contract.customer.street,
                        this.props.contract.customer.city,
                        this.props.contract.customer.zip,
                        this.props.contract.customer.billing_id_no,
                        this.props.contract.customer.billing_tax_no
                      ].filter(i => i).join(', ')}
                    </React.Fragment>
                  ) : null}
                  {this.props.contract.place && this.props.contract.place.has_billing_address ? (
                    <React.Fragment>
                      <br/>
                      <strong>Fakturační adresa místa</strong>
                      <br/>
                      {[
                        this.props.contract.place.billing_name,
                        this.props.contract.place.billing_street,
                        this.props.contract.place.billing_city,
                        this.props.contract.place.billing_zip
                      ].filter(i => i).join(', ')}
                    </React.Fragment>
                  ) : null}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <strong>Typ zákazníka</strong>
                  <br/>
                  <span className={classNames({'text-danger': this.props.contract.customer.kind == 'problematic'})}>
                    {I18n.t(`customer-kinds.${this.props.contract.customer.kind}`)}
                  </span>
                </div>
                <div className={classNames('col', {'d-none': this.props.contract.customer.kind != 'problematic'})}>
                  <strong>{I18n.t('activerecord.attributes.customer.problematic_note')}</strong>
                  <br/>
                  {this.props.contract.customer.problematic_note}
                </div>
                <div className={classNames('col', {'d-none': this.props.contract.customer.kind != 'new'})}>
                  <strong>{I18n.t('activerecord.attributes.customer.new_note')}</strong>
                  <br/>
                  {this.props.contract.customer.new_note}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-4">
                  <strong>PPAS</strong>
                  <br/>
                  {this.props.contract.place.has_ppas ? 'Ano' : 'Ne'}
                </div>
                <div className="col-sm-8">
                  <strong>Cesta</strong>
                  <br/>
                  <span className="btn-group float-right">
                    <a href={this.props.contract.google_navigate_url} target="_blank" className="btn btn-sm btn-outline-secondary">
                      <i className="fas fa-directions" style={{marginRight: '8px'}}></i>
                      Navigovat
                    </a>
                    <a href={this.props.contract.google_map_route_url} target="_blank" className="btn btn-sm btn-outline-secondary">Zobrazit na mapě</a>
                  </span>
                  Čas: {Helper.timeFormatted(this.props.contract.route_duration)}
                  <br/>
                  Vzdálenost: {Math.round(this.props.contract.route_distance/100)/10}&nbsp;km
                </div>
              </div>
              {this.props.contract.google_embed_route_url && !this.props.master ? (
                <iframe
                  className="route-preview"
                  src={this.props.contract.google_embed_route_url}
                  frameBorder="0"></iframe>
              ) : null}
            </React.Fragment>
          ) : null}
          <p>
            <strong>Technik</strong>
            {user ? (
              <span className='default-user-name'>
                <span className="avatar"><img src={user.avatar_url} /></span>
                <span className="name">{user.name}</span>
              </span>
            ) : null}
          </p>
          {this.props.contract.kind == 'standard' && this.props.contract.place ? (
            <div className="table-wrapper no-padding-bottom">
              <table className="table table-sm table-borderless">
                <thead>
                  <tr>
                    <th>Výrobce</th>
                    <th>Typ zařízení</th>
                    <th>RV</th>
                    <th>Poznámka</th>
                    <th>Verze</th>
                    <th>ID</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {(this.props.contract.device_jobs || []).filter(i => i.checked).map(device_job => {
                    let device = device_job.device
                    if (device) {
                      return (
                        <React.Fragment key={device.uuid}>
                          <tr
                            key={device.uuid}
                            className={classNames({'text-muted': !this.props.contract.device_jobs.find(i => i.checked && i.device_uuid == device.uuid)})}>

                            <td>
                              {device.manufacturer_link ? (
                                <a href={device.manufacturer_link} target="_blank">{device.manufacturer}</a>
                              ) : device.manufacturer}
                            </td>
                            <td>{device.type}</td>
                            <td>{device.year}</td>
                            <td>{device.note}</td>
                            <td>{device.version}</td>
                            <td>{device.uuid.split('-')[0]}</td>
                            <td>
                              {device.active === false ? (
                                <strong>Neaktivní</strong>
                              ) : null}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="7">
                              <DeviceImages
                                deviceUuid={device.uuid}
                                master={this.props.master} />
                            </td>
                          </tr>
                        </React.Fragment>
                      )
                    }
                  })}
                </tbody>
              </table>
            </div>
          ) : null}
          <p>
            <strong>Popis</strong>
            <br/>
            {this.props.contract.description}
          </p>
          {this.props.contract.kind == 'reservation' || this.props.contract.action_type == 'help' ? (
            <p>
              <strong>Popis technika</strong>
              <br/>
              {this.props.contract.technician_description}
            </p>
          ) : null}
        </div>

        <div className={classNames({'d-none': this.state.tab != 'info'})}>
          <table className="table table-sm table-hover contract-detail">
            <tbody>
              <tr>
                <td>{I18n.t('activerecord.attributes.contract.action_type')}</td>
                <td className="text-center">
                  {this.props.contract.action_type ? I18n.t(`action-types.${this.props.contract.action_type}`) : null}
                </td>
              </tr>

              {this.props.contract.action_type == 'installation' ? (
                <tr>
                  <td>{I18n.t('activerecord.attributes.contract.installation_detail')}</td>
                  <td className="text-center">
                    {this.props.contract.installation_detail}
                  </td>
                </tr>
              ) : null}

              <tr>
                <td>{I18n.t('activerecord.attributes.contract.customer_unavailable')}</td>
                <td className="text-center">
                  {this.props.contract.customer_unavailable ? (<i className="fas fa-check text-success"></i>) : (<i className="fas fa-times text-danger"></i>)}
                </td>
              </tr>

              {this.props.contract.action_type == 'consultation' && (
                <ConsultationJobsDetail contract={this.props.contract} />
              )}

              <tr>
                <td colSpan="2">
                  <strong>{I18n.t('activerecord.attributes.contract.technician_description')}</strong>
                  <br />
                  {this.props.contract.technician_description}
                </td>
              </tr>

            </tbody>
          </table>

          {this.props.contract.action_type != 'consultation' && this.props.contract.device_jobs.filter(device_job => device_job.checked).map(device_job =>
            <DeviceJobDetail
              key={device_job.uuid}
              contract={this.props.contract}
              deviceJob={device_job} />
          )}

          

          <div className='card bg-light mb-4'>
            <div className='card-header'>Položky fakturace</div>
            <table className="table table-sm">
              <thead>
                <tr>
                  <th>Položka</th>
                  <th>Kód</th>
                  <th>ZD</th>
                  <th>Množství</th>
                  <th className="text-right">Cena</th>
                  <th className="text-right">Celkem</th>
                </tr>
              </thead>
              <tbody>
                {this.props.contract.items.filter(i => i.value != null || i.value != undefined).map(i =>
                  <React.Fragment key={i.id}>
                    <tr>
                      <td style={{width: '40%'}}>
                        {i.vatRate ? `${i.name} - DPH ${i.vatRate}%` : i.name}
                      </td>
                      <td>{i.ean}</td>
                      <td>{i.warranty ? `${i.warranty} měs.` : ''}</td>
                      <td>
                        {i.amount}
                      </td>
                      <td className="text-right nowrap">
                        {i.value ? Helper.formatMoney(i.value) : ''}
                      </td>
                      <td style={{verticalAlign: 'middle'}} className="text-right nowrap">
                        {i.value && i.amount ? Helper.formatMoney(i.value * i.amount) : 0}
                      </td>
                    </tr>
                    {i.devices && i.devices.length ? (
                      <tr className="item-devices">
                        <td colSpan="5">
                          {i.devices.map(uuid => {
                            let device = devices.find(d => d.uuid == uuid)
                            return (
                              <div key={uuid}>
                                {device ? (
                                  <small>
                                    <Device device={device} />
                                  </small>
                                ) : null}
                              </div>
                            )
                          })}
                        </td>
                      </tr>
                    ) : null}
                  </React.Fragment>
                )}
                <tr>
                  <td colSpan="5" className="text-right align-middle">Celkem bez DPH</td>
                  <td className="text-right nowrap align-middle">{Helper.formatMoney(this.props.contract.total)}</td>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan="5" className="text-right align-middle">DPH {this.props.contract.vat_rate}%</td>
                  <td className="text-right nowrap align-middle">{Helper.formatMoney(this.props.contract.vat)}</td>
                  <td></td>
                </tr>
                <tr>
                  <th colSpan="5" className="text-right align-middle">Celkem vč. DPH</th>
                  <th className="text-right nowrap align-middle">{Helper.formatMoney(this.props.contract.total_vat)}</th>
                  <td></td>
                </tr>
                {this.props.contract.apply_proforma ? (
                  <React.Fragment>
                    <tr>
                      <th colSpan="5" className="text-right align-middle">Záloha č. {this.props.contract.apply_proforma.number}</th>
                      <th className="text-right nowrap align-middle">{Helper.formatMoney(this.props.contract.apply_proforma.value * -1)}</th>
                      <td></td>
                    </tr>
                    <tr>
                      <th colSpan="5" className="text-right align-middle">Celkem k úhradě</th>
                      <th className="text-right nowrap align-middle">{Helper.formatMoney(this.props.contract.to_pay)}</th>
                      <td></td>
                    </tr>
                  </React.Fragment>
                ) : null}

              </tbody>
            </table>

            <table className="table table-sm table-hover contract-detail">
              <tbody>
                <tr>
                  <td>{I18n.t('activerecord.attributes.contract.lower_vat')}</td>
                  <td className="text-center">
                    {this.props.contract.lower_vat ? (<i className="fas fa-check text-success"></i>) : (<i className="fas fa-times text-danger"></i>)}
                  </td>
                </tr>

                <tr>
                  <td>{I18n.t('activerecord.attributes.contract.reverse_charge')}</td>
                  <td className="text-center">
                    {this.props.contract.reverse_charge ? (<i className="fas fa-check text-success"></i>) : (<i className="fas fa-times text-danger"></i>)}
                  </td>
                </tr>

                <tr>
                  <td>{I18n.t('activerecord.attributes.contract.customer_e_agreement')}</td>
                  <td className="text-center">
                    {this.props.contract.customer_e_agreement ? (<i className="fas fa-check text-success"></i>) : (<i className="fas fa-times text-danger"></i>)}
                  </td>
                </tr>

                <tr className={classNames({'d-none': !this.props.contract.payment_type})}>
                  <td>{I18n.t('activerecord.attributes.contract.payment_type')}</td>
                  <td>{I18n.t(`payment-types.${this.props.contract.payment_type}`)}</td>
                </tr>

                <tr className={classNames({'d-none': this.props.contract.payment_type != 'ppas'})}>
                  <td>{I18n.t('activerecord.attributes.contract.ppas_partner_number')}</td>
                  <td>{this.props.contract.ppas_partner_number}</td>
                </tr>

                <tr className={classNames({'d-none': this.props.contract.payment_type != 'ppas'})}>
                  <td>{I18n.t('activerecord.attributes.contract.ppas_consumption')}</td>
                  <td>{this.props.contract.ppas_consumption}</td>
                </tr>

                <tr className={classNames({'d-none': this.props.contract.payment_type != 'ppas'})}>
                  <td>{I18n.t('activerecord.attributes.contract.ppas_meter_number')}</td>
                  <td>{this.props.contract.ppas_meter_number}</td>
                </tr>

                <tr>
                  <td>{I18n.t('activerecord.attributes.contract.customer_privacy_agreement')}</td>
                  <td className="text-center">
                    {this.props.contract.customer_privacy_agreement ? (<i className="fas fa-check text-success"></i>) : (<i className="fas fa-times text-danger"></i>)}
                  </td>
                </tr>

              </tbody>
            </table>
          </div>

          <div className='row text-center signatures'>
            <div className='col-sm-6'>
              <img src={this.props.contract.technician_signature_url} alt="" />
              <br/>
              podpis servisního technika
            </div>
            <div className='col-sm-6'>
              <img src={this.props.contract.customer_signature_url} alt="" />
              <br/>
              podpis zákazníka
            </div>
          </div>

          {this.props.contract.payment_type == 'qr' &&
            <QRPayment
              contract={this.props.contract}
              master={this.props.master} />
          }
        </div>

        <div className={classNames({'d-none': this.state.tab != 'photo'})}>
          <div className="mb-3">
            <div className="mb-3">
              <strong>Soubory dispečink</strong>
            </div>
            <div className="image-thumbs">
              {this.props.contract.operator_upload_files.filter(i => i.content_type.includes('image/')).map(i =>
                <a key={i.uuid} href={i.asset_url} target="_blank">
                  <img src={i.asset_url} alt=""/>
                </a>
              )}
            </div>

            <div className="upload-container">
              <div className="files">
                {this.props.contract.operator_upload_files.filter(i => !i.content_type.includes('image/')).map(i =>
                  <a href={i.asset_url} target="_blank" key={i.uuid} className="file">
                    <div className="preview">
                      <i className="far fa-file"></i>
                    </div>
                    <div className="filename">
                      {i.filename}
                    </div>
                  </a>
                )}
              </div>
            </div>

            {!this.props.master ? (
              <Uploader
                handleUploads={this.handleUploads.bind(this)}
                name="operator_upload_uuids"
                helperClass="in-modal"
                kind="operator"
                editLocked={this.props.contract.edit_locked}
                contractId={this.props.contract.id}
                files={this.state.operator_upload_files} />
            ) : null}
          </div>

          <div className="mb-3">
            <div className="mb-3">
              <strong>Fotografie technik</strong>
            </div>
            <div className="image-thumbs">
              {this.props.contract.technician_upload_files.map(i =>
                <a key={i.uuid} href={i.asset_url} target="_blank">
                  <img src={i.asset_url} alt=""/>
                </a>
              )}
            </div>
            {this.props.master ? (
              <Uploader
                handleUploads={this.handleUploads.bind(this)}
                name="technician_upload_uuids"
                helperClass="in-modal"
                kind="technician"
                accept="image/*"
                contractId={this.props.contract.id}
                files={this.state.technician_upload_uuids}
                master={this.props.master} />
            ) : null}
          </div>
        </div>

        <div className={classNames({'d-none': this.state.tab != 'documents'})}>
          <DocumentList documents={this.props.contract.documents} pdfView={true} />
        </div>

        <div className={classNames({'d-none': this.state.tab != 'requests'})}>
          <div className="changelog">
            {this.props.contract.requests.map((i, index) => {
              let userHelper = users.find(u => u.id == i.from_user_id)

              return (
                <Request key={index} request={i} user={userHelper} />
              )
            })}
          </div>

          {this.props.contract.previous_requests.length ? (
            <React.Fragment>
              <h5>Požadavky z předchozí zakázky {this.props.contract.previous_requests[0].contract_id}</h5>
              <div className="changelog">
                {this.props.contract.previous_requests.map(i => {
                  let userHelper = users.filter(u => u.id == i.from_user_id)[0]

                  return (
                    <Request key={i.id} request={i} user={userHelper} />
                  )
                })}
              </div>
            </React.Fragment>
          ) : null}

        </div>

        <div className={classNames({'d-none': this.state.tab != 'related_contracts'})}>

          {this.state.loading ? (
            <Loading />
          ) : null}

          <div className="form-group">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                checked={this.state.related_devices}
                id="related_devices"
                onChange={this.handleRelatedDevicesChange.bind(this)} />
              <label className="form-check-label" htmlFor="related_devices">
                Pouze související zařízení
              </label>
            </div>
          </div>

          <ContractList
            loading={this.state.loading}
            contracts={contracts}
            users={users}
            showContract={this.showContract.bind(this)} />
        </div>

        <div className={classNames({'d-none': this.state.tab != 'comments'})}>
          <Comments
            contractId={this.props.contract.id}
            accessToken={this.props.accessToken}
            tab={this.state.tab} />
        </div>

        <div className={classNames({'d-none': this.state.tab != 'log'})}>
          <div className="changelog">
            {_.sortBy(this.props.contract.history, ['id']).reverse().map((i, index) => {
              let userHelper = users.filter(u => u.id == i.user_id)[0]

              return (
                <LogChange key={i.time} object={i} user={userHelper} />
              )
            })}
          </div>

        </div>


      </div>
    )
  }
}

export default ContractModalBody
