window.helperData = {
  contract: {
    statuses: [{"value":"waiting","label":"Čeká na vyřízení"},{"value":"finished","label":"Dokončeno"},{"value":"cancelled","label":"Zrušeno"}],
    payment_types: [{"value":"cash","label":"Hotově"},{"value":"qr","label":"QR platba"},{"value":"card","label":"Kartou"},{"value":"transfer","label":"Převodem"},{"value":"ppas","label":"PPAS"},{"value":"other","label":"Bez úhrady"}],
    kinds: [{"value":"standard","label":"Standardní"},{"value":"reservation","label":"Rezervace"}],
    action_types: [{"value":"service","label":"Servisní prohlídka"},{"value":"repair","label":"Oprava"},{"value":"repair_service","label":"Servisní prohlídka + oprava"},{"value":"warranty","label":"Záruční oprava"},{"value":"consultation","label":"Konzultace (montáž kotle)"},{"value":"consultation_unpaid","label":"Konzultace (topenářské práce)"},{"value":"consultation_3","label":"Konzultace (montáž - tepelné čerpadlo)"},{"value":"consultation_4","label":"Konzultace (montáž zásobníku a ostatní)"},{"value":"installation","label":"Montáž"},{"value":"launch","label":"Uvedení do provozu (uhrazené)"},{"value":"launch_unpaid","label":"Uvedení do provozu (neuhrazené)"},{"value":"complaint","label":"Reklamace"},{"value":"complaint_billing","label":"Reklamace - vyúčtování"},{"value":"help","label":"Výpomoc"},{"value":"fault_location","label":"Lokalizace závady"},{"value":"heating_works","label":"Instalatérské a topenářské práce"},{"value":"flue_inspection_report","label":"Kontrola spalinové cesty"},{"value":"shutdown","label":"Odstavení provozu"},{"value":"none","label":"Žádný"},{"value":"missing_contract","label":"Chybějící zakázka"}],
    available_jobs: ["job_1","job_2","job_3","job_4","job_5","job_6","job_9","job_9b","job_10","job_11","job_12","job_12b","job_13","job_15","job_16","job_17","job_18","job_19","job_20","job_21","job_22","job_23","job_24","job_25","job_26","job_27","job_28","job_29","job_30","job_31","job_32","job_33","job_34","job_35"],
    standard_jobs: ["job_1","job_2","job_3","job_4","job_5","job_6","job_9","job_9b","job_10","job_11","job_12","job_12b","job_13","job_34","job_35"],
    installation_jobs: ["job_15","job_16","job_17","job_18","job_19","job_20","job_21","job_22","job_23","job_24","job_25","job_26","job_27","job_28","job_29","job_30","job_31","job_32","job_33"],
    installation_details: [{"value":"Výměna plynového kotle","label":"Výměna plynového kotle"},{"value":"Výměna elektrického kotle","label":"Výměna elektrického kotle"},{"value":"Výměna přímotopného zásobníku vody","label":"Výměna přímotopného zásobníku vody"},{"value":"Výměna nepřímotopného zásobníku vody","label":"Výměna nepřímotopného zásobníku vody"},{"value":"Výměna elektrického zásobníku vody","label":"Výměna elektrického zásobníku vody"},{"value":"Výměna průtokového ohřívače vody","label":"Výměna průtokového ohřívače vody"},{"value":"Výměna plynového topidla","label":"Výměna plynového topidla"},{"value":"Instalace tepelného čerpadla","label":"Instalace tepelného čerpadla"}]
  },
  customer: {
    email_statuses: [{"value":"has","label":"Ano"},{"value":"has_not","label":"Ne"},{"value":"missing","label":"Doplnit"}],
    kinds: [{"value":"new","label":"Nový"},{"value":"regular","label":"Stálý"},{"value":"problematic","label":"Problematický"},{"value":"inactive","label":"Neaktivní"}]
  },
  google_api_key: 'AIzaSyDNfKN9atzXS-EkGANIsdzbMVd47w4Uak8',
  edit_lock_path: '/api/v1/edit-lock',
  vat_rates: [
    {
      value: 21.0,
      label: '21%',
      from: '2000-01-01'
    },{
      value: 12.0,
      label: '12%',
      from: '2024-01-01',
    },{
      value: 15.0,
      label: '15%',
      from: '2000-01-01',
      to: '2023-12-31'
    }
  ],
  inflationRate: 0.0407,
  inflationRates: {"2021":0.038,"2022":0.0407}
}
